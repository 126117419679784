import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.match";
export var formatDate = function formatDate(inputDateStr, inputFormat, outputFormat) {
  // 解析输入格式的字符串日期
  var inputDate = parseDate(inputDateStr, inputFormat);

  // 格式化日期为指定格式的字符串日期
  return formatOutputDate(inputDate, outputFormat);
};
function parseDate(dateStr, format) {
  // 使用正则表达式匹配日期格式的不同部分
  var regex = /(\d{1,4})/g;
  var parts = dateStr.match(regex);
  var formatParts = format.match(/(yyyy|yy|MM|M|dd|d|HH|H|mm|m|ss|s)/g);
  var dateValues = {};

  // 根据格式提取日期的年、月、日、时、分、秒部分
  for (var i = 0; i < formatParts.length; i++) {
    switch (formatParts[i]) {
      case 'yyyy':
        dateValues.year = parseInt(parts[i], 10);
        break;
      case 'yy':
        dateValues.year = parseInt(parts[i], 10) + 2000;
        break;
      case 'MM':
      case 'M':
        dateValues.month = parseInt(parts[i], 10) - 1;
        break;
      case 'dd':
      case 'd':
        dateValues.day = parseInt(parts[i], 10);
        break;
      case 'HH':
      case 'H':
        dateValues.hour = parseInt(parts[i], 10);
        break;
      case 'mm':
      case 'm':
        dateValues.minute = parseInt(parts[i], 10);
        break;
      case 'ss':
      case 's':
        dateValues.second = parseInt(parts[i], 10);
        break;
    }
  }

  // 创建日期对象并返回
  return new Date(dateValues.year, dateValues.month, dateValues.day, dateValues.hour || 0, dateValues.minute || 0, dateValues.second || 0);
}
function formatOutputDate(date, format) {
  // 将日期对象格式化为指定格式的字符串日期
  var year = date.getFullYear();
  var month = padZero(date.getMonth() + 1);
  var day = padZero(date.getDate());
  var hour = padZero(date.getHours());
  var minute = padZero(date.getMinutes());
  var second = padZero(date.getSeconds());

  // 替换格式字符串中的占位符
  return format.replace('yyyy', year).replace('yy', year - 2000).replace('MM', month).replace('M', month).replace('dd', day).replace('d', day).replace('HH', hour).replace('H', hour).replace('mm', minute).replace('m', minute).replace('ss', second).replace('s', second);
}
function padZero(num) {
  return num < 10 ? '0' + num : num;
}