import service from '@/utils/request';
export var getQeDashList = function getQeDashList(params) {
  return service({
    url: "/qeDashConfig/getQeDashList",
    method: 'GET',
    params: params
  });
};
export var getQeDashConfigListPage = function getQeDashConfigListPage(params) {
  return service({
    url: "/qeDashConfig/getQeDashConfigListPage",
    method: 'GET',
    params: params
  });
};
export var getQeConfigListByGroupId = function getQeConfigListByGroupId(data) {
  return service({
    url: "/qeDashConfig/getQeConfigListByGroupId",
    method: 'POST',
    data: data
  });
};
export var getAllQeDashConfig = function getAllQeDashConfig(params) {
  return service({
    url: "/qeDashConfig/getAllQeDashConfig",
    method: 'GET',
    params: params
  });
};
export var getQeConfigsByUserId = function getQeConfigsByUserId(params) {
  return service({
    url: "/qeDashConfig/getQeConfigsByUserId",
    method: 'GET',
    params: params
  });
};
export var getQeDashConfigById = function getQeDashConfigById(params) {
  return service({
    url: "/qeDashConfig/getQeDashConfigById",
    method: 'GET',
    params: params
  });
};
export var qeDashConfigAddOrUpdate = function qeDashConfigAddOrUpdate(data) {
  return service({
    url: "/qeDashConfig/qeDashConfigAddOrUpdate",
    method: 'put',
    data: data
  });
};
export var deleteQeDashConfig = function deleteQeDashConfig(data) {
  return service({
    url: "/qeDashConfig/deleteQeDashConfig",
    method: 'delete',
    data: data
  });
};