import service from '@/utils/request';
export var qeDymTableListByParam = function qeDymTableListByParam(data) {
  return service({
    url: "/qeDymTableOpt/qeDymTableListByParam",
    method: 'post',
    data: data
  });
};
export var qeDymTableAddOrUpdate = function qeDymTableAddOrUpdate(data) {
  return service({
    url: "/qeDymTableOpt/qeDymTableAddOrUpdate",
    method: 'post',
    data: data
  });
};
export var qeDymTableDeleteById = function qeDymTableDeleteById(data) {
  return service({
    url: "/qeDymTableOpt/qeDymTableDeleteById",
    method: 'post',
    data: data
  });
};